import * as React from 'react';
// import { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Mousewheel } from 'swiper';
import FancyTitle from './fancy-title';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/virtual';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css/effect-cards';

function History({ title, subtitle, items }) {
  if (typeof window !== 'undefined') {
    const matchHeighthistoryItem = () => {
      const getSlideItems = document.getElementsByClassName('history-slide-item');
      const arrayLength = getSlideItems.length;
      const heights = [];
      for (let i = 0; i < arrayLength; i++) {
        heights.push(getSlideItems[i].offsetHeight);
      }
      function getHighest() {
        return Math.max(...heights);
      }
      const tallest = getHighest();
      for (let i = 0; i < getSlideItems.length; i++) {
        // eslint-disable-next-line
        getSlideItems[i].style.height = tallest + 'px';
      }
    };

    matchHeighthistoryItem();
  }

  return (
    <>
      <div className="lg:text-center flex flex-col lg:pr-16 py-8 lg:py-0 mt-[100px] lg:mt-60">
        {title && (
          <FancyTitle
            text={title}
            subtitle={subtitle}
            marginBottom="mb-20"
            textAlign="text-left"
            className="lg:w-fit lg:mx-auto"
            tag="h2"
          />
        )}
      </div>
      <div>
        <Swiper
          modules={[EffectCards, Mousewheel]}
          className="history-card-slider"
          effect="cards"
          grabCursor="true"
          initialSlide={0}
          slidesPerView={1}
          loop="true"
          slide
          speed={500}
          // width={500}
          rotate="true"
          mousewheel={{
            invert: false,
          }}
        >
          {items && items.map((item, index) => (
            <SwiperSlide
              key={item.year}
              virtualIndex={index}
              className="lg:max-h-[800px] rounded border-2 rounded border-latori-green-dark flex justify-center items-start p-3 md:p-5 history-slide-item"
            >
              <div>
                <GatsbyImage
                  image={getImage(item.image)}
                  alt={item.title}
                  className="w-full rounded"
                  objectFit="contain"
                />
                { item.title && (
                  <FancyTitle
                    text={item.title}
                    className="text-start mb-[16px] lg:mb-5 mt-[16px] lg:mt-5"
                    tag="h2"
                  />
                )}
                { item.text && (
                  <p className="prose dark:text-white text-background text-sm md:text-base" dangerouslySetInnerHTML={{ __html: item.text }} />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    </>
  );
}

export default History;
